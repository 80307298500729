import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-03b090a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "about__employes_text" }
const _hoisted_2 = {
  key: 0,
  class: "about__employes_cont"
}
const _hoisted_3 = {
  key: 1,
  class: "about__employes_cont"
}
const _hoisted_4 = {
  key: 2,
  class: "preloader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_element_employe = _resolveComponent("element-employe")!
  const _component_glider_employes = _resolveComponent("glider-employes")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      about__employes: true,
      active:
        (_ctx.getModal || _ctx.getModalLetter || _ctx.getModalPrivacy || _ctx.getMenu) &&
        !_ctx.checkDevice(),
    })
  }, [
    _createElementVNode("h2", _hoisted_1, [
      _createTextVNode(_toDisplayString(_ctx.getStaticLanguage.textEmployes) + " ", 1),
      _createElementVNode("span", null, _toDisplayString(_ctx.getStaticLanguage.textEmployesSpan), 1)
    ]),
    (!_ctx.getProcessE && !_ctx.getTablet)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allEmployes, (employes) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "element_employes",
              id: "desktop_employes",
              key: employes.id
            }, [
              _createVNode(_component_element_employe, { employes: employes }, null, 8, ["employes"])
            ]))
          }), 128))
        ]))
      : (!_ctx.getProcessE && _ctx.getTablet)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_glider_employes, { allEmployes: _ctx.allEmployes }, null, 8, ["allEmployes"])
          ]))
        : (_openBlock(), _createElementBlock("p", _hoisted_4))
  ], 2))
}