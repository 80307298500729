
import { defineComponent } from '@vue/runtime-core';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { Head } from '@vueuse/head';
import FeedBack from '../components/FeedBack/FeedBack.vue';
import LayoutBlockFilter from '../components/LayoutBlockFilter/LayoutBlockFilter.vue';

export default defineComponent({
  methods: {
    ...mapActions([
      'getAllProjects',
      'getFiltersProjects',
    ]),
    ...mapMutations([
      'setCategoriesProject',
    ]),
    pushTo(url: string): void {
      this.$router.push({ path: `/projects/${url}` });
    },
  },
  components: {
    FeedBack,
    Head,
    LayoutBlockFilter,
  },
  computed: mapGetters(['getStaticLanguage', 'getCategoriesProject', 'allProjects', 'getProcessProjects']),
  async mounted(): Promise<void> {
    const language = window.localStorage.getItem('language');
    await this.getFiltersProjects().then(() => {
      this.setCategoriesProject({ id: 0, categoryNew: 'All projects' });
    });
    await this.getAllProjects(language);
  },
});
