import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-19dad9c8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "letter__lottie" }
const _hoisted_2 = { class: "success__let" }
const _hoisted_3 = { class: "success__let_small" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lottie_animation = _resolveComponent("lottie-animation")!

  return (_openBlock(), _createBlock(_Transition, {
    name: "modalTransition",
    duration: 600
  }, {
    default: _withCtx(() => [
      (_ctx.getModalLetter)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "modal",
            onClick: _cache[2] || (_cache[2] = () => _ctx.setModalLetter(false))
          }, [
            _createElementVNode("div", {
              class: "modal__letter_content",
              onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
            }, [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_lottie_animation, { path: "./images/Letter.json" })
              ]),
              _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.getStaticLanguage.successSend), 1),
              _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.getStaticLanguage.successSendContact), 1),
              _createElementVNode("button", {
                class: "success__button_ok",
                onClick: _cache[0] || (_cache[0] = () => _ctx.setModalLetter(false))
              }, _toDisplayString(_ctx.getStaticLanguage.successSendButton), 1)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}