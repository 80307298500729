import {
  createRouter,
  createWebHistory,
  HistoryState,
  RouteLocationNormalized,
  RouteLocationNormalizedLoaded,
  RouteRecordRaw,
} from 'vue-router';
import AboutUs from '../views/AboutUs.vue';
import Vacancies from '../views/Vacancies.vue';
import NotFound from '../views/NotFound.vue';
import Projects from '@/views/Projects.vue';
import Blog from '@/views/Blog.vue';
import ProjectsItem from '@/views/ProjectItem.vue';
import BlogItem from '@/views/BlogItem.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Main',
    component: AboutUs,
    redirect: '/about-us',
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: AboutUs,
  },
  {
    path: '/vacancies',
    name: 'Vacancies',
    component: Vacancies,
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects,
  },
  {
    path: '/projects/:id',
    name: 'ProjectsItem',
    component: ProjectsItem,
  },
  {
    path: '/blog',
    name: 'Blog',
    component: Blog,
  },
  {
    path: '/blogs/:id',
    name: 'BlogItem',
    component: BlogItem,
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(
    _to: RouteLocationNormalized,
    _from: RouteLocationNormalizedLoaded,
    savedPosition: HistoryState | null
  ): HistoryState {
    if (savedPosition) {
      return savedPosition;
    }
    if (_to.hash) {
      return {
        selector: _to.hash
      };
    }

    return { x: 0, y: 0 };
  },
});

router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router;
