import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9a82c3d2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "vacancies__elements" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "lottie__block" }
const _hoisted_4 = { class: "vacancie__element_text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lottie_animation = _resolveComponent("lottie-animation")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, (vacancie) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "vacancie__element",
        key: vacancie.id,
        onClick: 
        () => {
          $props.setVacan(vacancie);
          $props.setModal(true);
        }
      
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_lottie_animation, {
            path: 
            vacancie.name === 'Android Reverse Engineer'
              ? './images/Android.json'
              : './images/Java.json'
          
          }, null, 8, ["path"])
        ]),
        _createElementVNode("p", _hoisted_4, _toDisplayString(vacancie.name), 1)
      ], 8, _hoisted_2))
    }), 128))
  ]))
}