
import { defineComponent } from '@vue/runtime-core';
import { mapGetters, mapMutations } from 'vuex';
import HrLinks from '../HrLinks/HrLinks.vue';

export default defineComponent({
  components: {
    HrLinks,
  },
  methods: {
    ...mapMutations(['updateLanguage', 'setMenu'])
  },
  computed: {
    ...mapGetters(['getLanguage', 'getMenu', 'getStaticLanguage']),
  },
});
