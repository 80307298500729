
import { defineComponent } from '@vue/runtime-core';
import { mapGetters } from 'vuex';
import ElementEmploye from '../ElementEmploye/ElementEmploye.vue';
import GliderEmployes from '../GliderEmployes/GliderEmployes.vue';

export default defineComponent({
  name: 'about-employes',
  components: {
    GliderEmployes,
    ElementEmploye,
  },
  computed: mapGetters([
    'allEmployes',
    'getTablet',
    'getProcessE',
    'getModal',
    'getModalLetter',
    'getModalPrivacy',
    'getMenu',
    'getStaticLanguage',
  ]),
  methods: {
    checkDevice(): boolean {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
        navigator.userAgent
      );
    },
  },
});
