import { createApp } from 'vue';
import { createHead } from '@vueuse/head';
import components from '@/UI/index';
import App from './App.vue';
import router from './router';
import store from './store';

const app = createApp(App);
const head = createHead();

components.forEach(item => app.component(item.name, item.component));

app
  .use(store)
  .use(router)
  .use(head)
  .mount('#app');
