
import { defineComponent } from '@vue/runtime-core';
import { mapGetters, mapMutations } from 'vuex';

export default defineComponent({
  methods: {
    ...mapMutations(['setModalLetter']),
  },
  computed: mapGetters(['getModalLetter', 'getStaticLanguage']),
});
