
import moment from 'moment/moment';
import { defineComponent } from '@vue/runtime-core';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { Head } from '@vueuse/head';
import FeedBack from '../components/FeedBack/FeedBack.vue';
import LayoutBlockFilter from '../components/LayoutBlockFilter/LayoutBlockFilter.vue';

export default defineComponent({
  methods: {
    ...mapActions([
      'getAllBlog',
      'getFiltersBlog'
    ]),
    ...mapMutations([
      'setCategoriesBlog',
    ]),
    pushTo(url: string): void {
      this.$router.push({ path: `/blogs/${url}` });
    }
  },
  computed: {
    ...mapGetters(['getStaticLanguage', 'getCategoriesBlog', 'allBlog', 'getProcessBlog']),
    transformDate() {
      return (value: string) => {
        if (value) {
          const date = new Date(value);
          return moment(String(date)).format('MMMM DD, YYYY').toUpperCase();
        }
        return '';
      };
    },
  },
  components: {
    FeedBack,
    Head,
    LayoutBlockFilter,
  },
  async mounted(): Promise<void> {
    const language = window.localStorage.getItem('language');
    await this.getFiltersBlog().then(() => {
      this.setCategoriesBlog({ id: 0, categoryNew: 'All blogs' });
    });
    await this.getAllBlog(language);
  },
});
