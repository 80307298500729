
import { defineComponent } from '@vue/runtime-core';
import { mapGetters, mapMutations } from 'vuex';
import LayoutElementsVacancies from '../LayoutElementsVacancies/LayoutElementsVacancies.vue';

export default defineComponent({
  components: {
    LayoutElementsVacancies,
  },
  computed: mapGetters(['activeVacancies', 'getProcessV', 'getStaticLanguage']),
  methods: {
    ...mapMutations(['setVacan', 'setModal']),
  },
});
