import { BACKEND_URL } from '../constantURL';

interface IInterns {
  name: string;
  responsibilities: [string];
  requirements: [string];
  advantages: [string];
  conditions: [string];
}

interface IInternsDto {
  interns: IInterns[];
}

interface ICommitType {
  commit(commit: string, payload: IInterns[] | boolean): void;
}

interface IIntersState {
  interns: IInterns[];
  processI: boolean;
}

export default {
  actions: {
    async getAllInterns(ctx: ICommitType, lang: string | null): Promise<void> {
      lang = lang ?? 'en';
      ctx.commit('updateProcessInterns', true);
      const data = await fetch(`${BACKEND_URL}/interns?` + new URLSearchParams({
        lang,
      }));
      const interns: IInternsDto = await data.json();
      ctx.commit('updateInterns', interns.interns);
    },
  },
  mutations: {
    updateInterns(
      state: IIntersState,
      newIntern: IInterns[],
    ): void {
      state.interns = newIntern;
      state.processI = false;
    },
    updateProcessInterns(state: IIntersState, newProcess: boolean) {
      state.processI = newProcess;
    },
  },
  state: (): IIntersState => ({
    interns: [],
    processI: true,
  }),
  getters: {
    allInterns(state: IInternsDto): IInterns[] {
      return state.interns;
    },
    getProcessI(state: { processI: boolean }): boolean {
      return state.processI;
    },
  },
};
