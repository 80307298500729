import { BACKEND_URL } from '../constantURL';

export interface IProjects {
  projectId: string;
  state: string;
  projectTypeName: string;
  header: string;
  beginning: string;
  avatarPath: string;
  articlePath: string;
  siteUrl: string;
  youtubeUrl: string;
  tiktokUrl: string;
  mediumUrl: string;
  habrUrl: string;
  technologies: string[];
}

interface ICategoriesFilter {
  active: boolean;
  category: string;
  id: number;
}

interface IFilterProject {
  'projectTypeId': number,
  'projectTypeName': string
}

interface IProjectsDto {
  projects: IProjects[];
  categoriesFilter: ICategoriesFilter[];
  projectTypes: IFilterProject[];
}

interface ICommitType {
  commit(commit: string, payload: IProjects[] | boolean | string | IFilterProject[] | { id: number, categoryNew: string }): void;
}

const initialFilters = [{
  active: false,
  category: 'All projects',
  id: 0,
}];

interface IProjectsState {
  projects: IProjects[];
  initialProjects: IProjects[];
  processProjects: boolean;
  categoriesFilter: { id: number, active: boolean, category: string }[];
}

export default {
  actions: {
    async getAllProjects(ctx: ICommitType, lang: string | null): Promise<void> {
      lang = lang ?? 'en';
      ctx.commit('updateProcessProjects', true);
      const data = await fetch(`${BACKEND_URL}/projects?` + new URLSearchParams({ lang }));
      const projects: IProjectsDto = await data.json();
      ctx.commit('updateProjects', projects.projects);
      const items = (ctx as any).state.categoriesFilter.find((categoryId: ICategoriesFilter) => categoryId.active);
      ctx.commit('setCategoriesProject', { id: items.id, categoryNew: items.category });
    },
    async getFiltersProjects(ctx: ICommitType): Promise<void> {
      const data = await fetch(`${BACKEND_URL}/projectTypes?lang=en`);
      const filters: IProjectsDto = await data.json();
      ctx.commit('updateFiltersProjects', filters.projectTypes);
    },
  },
  mutations: {
    updateProjects(
      state: IProjectsState,
      newProjects: IProjects[],
    ): void {
      state.projects = newProjects;
      state.initialProjects = newProjects;
      state.processProjects = false;
    },
    updateProcessProjects(state: IProjectsState, newProcess: boolean): void {
      state.processProjects = newProcess;
    },
    setCategoriesProject(state: IProjectsState, newCat: { id: number, categoryNew: string }): void {
      state.categoriesFilter = state.categoriesFilter.map((category) => {
        if (category.id === newCat.id) category.active = true;
        else category.active = false;
        return category;
      });
      if (newCat.id === 0) {
        state.projects = state.initialProjects;
      } else {
        state.projects = state.initialProjects.filter((project) => project.projectTypeName.toLowerCase() === newCat.categoryNew.toLowerCase());
      }
    },
    updateFiltersProjects(state: IProjectsState, filters: IFilterProject[]): void {
      state.categoriesFilter = initialFilters;
      const filtersR = filters.sort((a, b) => a.projectTypeId - b.projectTypeId).map((item) => ({
        active: false,
        category: item.projectTypeName,
        id: item.projectTypeId,
      }));

      state.categoriesFilter = [...state.categoriesFilter, ...filtersR];
    },
  },
  state: (): IProjectsState => ({
    projects: [],
    initialProjects: [],
    processProjects: true,
    categoriesFilter: initialFilters,
  }),
  getters: {
    allProjects(state: IProjectsDto): IProjects[] {
      return state.projects;
    },
    getProcessProjects(state: { processProjects: boolean }): boolean {
      return state.processProjects;
    },
    getCategoriesProject(state: IProjectsDto): ICategoriesFilter[] {
      return state.categoriesFilter;
    },
  },
};
