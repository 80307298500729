
import { mapGetters } from 'vuex';
import { defineComponent } from '@vue/runtime-core';

export default defineComponent({
  data(): { cookies: boolean } {
    return {
      cookies: false,
    };
  },
  computed: mapGetters(['getStaticLanguage', 'getStaticCookies']),
  mounted() {
    this.cookies = JSON.parse(localStorage.getItem('cookies')!)?.cookies || false;
  },
  methods: {
    removeCookies() {
      this.cookies = true;
      localStorage.setItem('cookies', JSON.stringify({ cookies: true }));
    },
  },
});
