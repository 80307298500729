import { languageEn, languageRu } from './words';

interface IItems {
  text: string;
  span: string;
  sum: string;
}

interface IGifts {
  path: string;
  main: string;
  text: string;
}

interface ILinks {
  path: string;
  word: string;
}

export interface IStaticWords {
  links: ILinks[];
  logoHead: string;
  logoHeadSpan: string;
  textHead: string;
  buttonHeadText: string;
  dataItems: IItems[];
  gifts: IGifts[];
  textEmployes: string;
  textEmployesSpan: string;
  textFindPeople: string;
  viewVacancies: string;
  feedBack: string;
  feedBackText: string;
  formName: string;
  formPosition: string;
  formLink: string;
  formButton: string;
  formPrivacy: string;
  formPrivacyMain: string;
  rights: string;
  internsText: string;
  vacanciesPaid: string;
  successSend: string;
  successSendContact: string;
  successSendButton: string;
  privacyTitle: string;
  privacyText1: string;
  privacyText2: string;
  privacyMain1: string;
  privacyLabel1: string;
  privacyLabel2: string;
  privacyMain2: string;
  privacyLi1: string;
  privacyLi2: string;
  privacyMain3: string;
  privacyLabel3: string;
  privacyLabel4: string;
  privacyMain4: string;
  privacyLabel5: string;
  privacyMain5: string;
  privacyLabel6: string;
  privacyLi3: string;
  privacyLi4: string;
  privacyLi5: string;
  privacyLi6: string;
  privacyLi7: string;
  privacyLi8: string;
  privacyLi9: string;
  privacyLi10: string;
  privacyLi11: string;
  privacyMain6: string;
  privacyLabel7: string;
  privacyLabel8: string;
  privacyLi12: string;
  privacyLi13: string;
  privacyLabel9: string;
  privacyMain7: string;
  privacyNameCompany: string;
  privacyLocaleCompany: string;
  closeModal: string;
  applyVacancie: string;
  responsibilities: string;
  requirements: string;
  advantages: string;
  conditions: string;
  cookies: string;
  cookiesOk: string;
  ourProjects: string;
  blog: string;
  allProjectsCategory: string;
  allBlogCategory: string
  buttonMore: string;
}

interface ILanguage {
  language: string;
  staticLanguageRu: IStaticWords;
  staticLanguageEn: IStaticWords;
}

export default {
  actions: {},
  mutations: {
    updateLanguage(state: ILanguage, change: string): void {
      if (change === state.language) {
        return;
      }
      state.language = change;
      window.localStorage.setItem('language', change);
      (this as any).commit('setMenu', false, { root: true });
      (this as any).dispatch('getAllVacancies', change, { root: true });
      (this as any).dispatch('getAllInterns', change, { root: true });
      if (/projects/.test(window.location.href)) {
        (this as any).dispatch('getAllProjects', change, { root: true });
      }
      if (/blog/.test(window.location.href)) {
        (this as any).dispatch('getAllBlog', change, { root: true });
      }
    },
    updateLanguageLoad(state: ILanguage, change: string): void {
      state.language = change;
      window.localStorage.setItem('language', change);
    },
  },
  state: (): ILanguage => ({
    language: window.localStorage.getItem('language') || 'en',
    staticLanguageRu: {
      ...languageRu,
    },
    staticLanguageEn: {
      ...languageEn,
    },
  }),
  getters: {
    getLanguage(state: ILanguage): string {
      return state.language;
    },
    getStaticLanguage(state: ILanguage): IStaticWords {
      if (state.language === 'ru') {
        return state.staticLanguageRu;
      }
      return state.staticLanguageEn;
    },
    getStaticCookies(state: ILanguage): (number: number) => string {
      function getLanguageRuOrEn(lang: IStaticWords, number: number): string {
        const arrayCookie = lang.cookies.split(' ');
        return arrayCookie.slice(!number ? 0 : number === 2 ? 3 : 6, !number ? 3 : number === 2 ? 6 : arrayCookie.length - 1).join(' ');
      }

      if (state.language === 'ru') {
        return (number: number) => getLanguageRuOrEn(state.staticLanguageRu, number);
      }
      return (number: number) => getLanguageRuOrEn(state.staticLanguageEn, number);
    },
  },
};
