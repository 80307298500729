
import { defineComponent } from '@vue/runtime-core';
import { Head } from '@vueuse/head';
import FeedBack from '../components/FeedBack/FeedBack.vue';
import SoftItem from '../components/SoftItem/SoftItem.vue';
import { BACKEND_URL } from '@/constantURL';
import { IProjects } from '../modules/projectsModule';

export default defineComponent({
  data(): {projects: IProjects, loading: boolean } {
    return { projects: {} as IProjects, loading: true };
  },
  methods: {
  },
  components: {
    FeedBack,
    Head,
    SoftItem
  },
  async created(): Promise<void> {
    this.loading = true;
    const param = this.$route.params.id;
    if (param) {
      const data = await fetch(`${BACKEND_URL}/projects/${param}`);
      const projects: IProjects = await data.json();
      if (data.status === 200) {
        this.projects = projects;
        this.loading = false;
      } else {
        this.$router.push('/projects');
      }
    } else {
      this.$router.push('/projects');
    }
  },
});
