
import { defineComponent } from '@vue/runtime-core';
import { mapGetters } from 'vuex';

export default defineComponent({
  props: ['isProjects'],
  computed: mapGetters(['getStaticLanguage']),
  methods: {
    goToUrl(isProjects: boolean): void {
      this.$router.push(isProjects ? '/projects' : '/blog');
    }
  }
});
