import { BACKEND_URL } from '../constantURL';

export interface IBlog {
  blogId: number;
  createDate: number;
  author: string;
  header: string;
  beginning: string;
  avatarPath: string;
  articlePath: string;
  youtubeUrl: string;
  tiktokUrl: string;
  mediumUrl: string;
  habrUrl: string;
  categoryName: string;
}

interface ICategoriesFilter {
  active: boolean;
  category: string;
  id: number;
}

interface IFilterBlog {
  'blogCategoryId': number,
  'blogCategoryName': string
}

interface IBlogDto {
  blogs: IBlog[];
  categoriesFilter: ICategoriesFilter[];
  blogCategories: IFilterBlog[];
}

interface IBlogState {
  blogs: IBlog[];
  initialBlogs: IBlog[];
  processBlog: boolean;
  categoriesFilter: { id: number, active: boolean, category: string }[];
}

const initialFilters = [{
  active: false,
  category: 'All blogs',
  id: 0,
}];

interface ICommitType {
  commit(commit: string, payload: IBlog[] | boolean | string | IFilterBlog[] | { id: number, categoryNew: string }): void;
}

export default {
  actions: {
    async getAllBlog(ctx: ICommitType, lang: string | null): Promise<void> {
      lang = lang ?? 'en';
      ctx.commit('updateProcessBlog', true);
      const data = await fetch(`${BACKEND_URL}/blogs?` + new URLSearchParams({ lang }));
      const blog: IBlogDto = await data.json();
      ctx.commit('updateBlog', blog.blogs);
      const items = (ctx as any).state.categoriesFilter.find((categoryId: ICategoriesFilter) => categoryId.active);
      ctx.commit('setCategoriesBlog', { id: items.id, categoryNew: items.category });
    },
    async getFiltersBlog(ctx: ICommitType): Promise<void> {
      const data = await fetch(`${BACKEND_URL}/blogCategories?lang=en`);
      const filters: IBlogDto = await data.json();
      ctx.commit('updateFiltersProjects', filters.blogCategories);
    },
  },
  mutations: {
    updateBlog(
      state: IBlogState,
      newBlogs: IBlog[],
    ): void {
      state.blogs = newBlogs;
      state.initialBlogs = newBlogs;
      state.processBlog = false;
    },
    updateProcessBlog(state: IBlogState, newProcess: boolean): void {
      state.processBlog = newProcess;
    },
    setCategoriesBlog(state: IBlogState, newCat: { id: number, categoryNew: string }): void {
      state.categoriesFilter = state.categoriesFilter.map((category) => {
        if (category.id === newCat.id) category.active = true;
        else category.active = false;
        return category;
      });
      if (newCat.id === 0) {
        state.blogs = state.initialBlogs;
      } else {
        state.blogs = state.initialBlogs.filter((blog) => blog.categoryName.toLowerCase() === newCat.categoryNew.toLowerCase());
      }
    },
    updateFiltersProjects(state: IBlogState, filters: IFilterBlog[]): void {
      state.categoriesFilter = initialFilters;
      const filtersR = filters.sort((a, b) => a.blogCategoryId - b.blogCategoryId).map((item) => ({
        active: false,
        category: item.blogCategoryName,
        id: item.blogCategoryId,
      }));

      state.categoriesFilter = [...state.categoriesFilter, ...filtersR];
    },
  },
  state: (): IBlogState => ({
    blogs: [],
    initialBlogs: [],
    categoriesFilter: initialFilters,
    processBlog: true,
  }),
  getters: {
    allBlog(state: IBlogDto): IBlog[] {
      return state.blogs;
    },
    getProcessBlog(state: { processBlog: boolean }): boolean {
      return state.processBlog;
    },
    getCategoriesBlog(state: IBlogDto): ICategoriesFilter[] {
      return state.categoriesFilter;
    },
  },
};
