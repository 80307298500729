import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-68085202"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "vacancies_cont_block" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "container_vacan" }
const _hoisted_4 = { class: "container_vacan_item" }
const _hoisted_5 = {
  key: 0,
  class: "about__employes_cont"
}
const _hoisted_6 = {
  key: 1,
  class: "preloader"
}
const _hoisted_7 = { class: "container_vacan_item" }
const _hoisted_8 = { class: "gray" }
const _hoisted_9 = {
  key: 0,
  class: "about__employes_cont"
}
const _hoisted_10 = {
  key: 1,
  class: "preloader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_layout_elements_vacancies = _resolveComponent("layout-elements-vacancies")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h2", null, _toDisplayString(_ctx.getStaticLanguage.vacanciesPaid), 1),
          (!_ctx.getProcessV)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_layout_elements_vacancies, {
                  items: _ctx.allVacancies,
                  setVacan: _ctx.setVacan,
                  setModal: _ctx.setModal
                }, null, 8, ["items", "setVacan", "setModal"])
              ]))
            : (_openBlock(), _createElementBlock("p", _hoisted_6))
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("h2", _hoisted_8, _toDisplayString(_ctx.getStaticLanguage.internsText), 1),
          (!_ctx.getProcessI)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createVNode(_component_layout_elements_vacancies, {
                  items: _ctx.allInterns,
                  setVacan: _ctx.setVacan,
                  setModal: _ctx.setModal
                }, null, 8, ["items", "setVacan", "setModal"])
              ]))
            : (_openBlock(), _createElementBlock("p", _hoisted_10))
        ])
      ])
    ])
  ]))
}