import { BACKEND_URL } from '../constantURL';

export interface IVacancies {
  name: string;
  responsibilities: [string];
  requirements: [string];
  advantages: [string];
  conditions: [string];
  isActive: boolean;
}

export interface IVacanciesDto {
  vacancies: IVacancies[];
}

interface ICommitType {
  commit(commit: string, payload: IVacancies[] | boolean): void;
}

interface IVacanciesState {
  vacancies: IVacancies[];
  processV: boolean;
}

export default {
  actions: {
    async getAllVacancies(ctx: ICommitType, lang: string | null): Promise<void> {
      lang = lang ?? 'en';
      ctx.commit('updateProcessVacancies', true);
      const data = await fetch(`${BACKEND_URL}/vacancies?` + new URLSearchParams({
        lang,
      }));
      const vacancies: IVacanciesDto = await data.json();
      ctx.commit('updateVacancies', vacancies.vacancies);
    },
  },
  mutations: {
    updateVacancies(
      state: IVacanciesState,
      newVacancie: IVacancies[],
    ): void {
      state.vacancies = newVacancie;
      state.processV = false;
    },
    updateProcessVacancies(state: IVacanciesState, newProcess: boolean) {
      state.processV = newProcess;
    },
  },
  state: (): IVacanciesState => ({
    vacancies: [],
    processV: true,
  }),
  getters: {
    allVacancies(state: IVacanciesDto): IVacancies[] {
      return state.vacancies;
    },
    activeVacancies(state: IVacanciesDto): IVacancies[] {
      return [...state.vacancies].filter(
        (item: IVacancies) => item.isActive === true,
      );
    },
    getProcessV(state: { processV: boolean }): boolean {
      return state.processV;
    },
  },
};
