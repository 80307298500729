import { BACKEND_URL } from '../constantURL';

interface IEmployes {
  avatarLink: string;
  name: string;
  workExperience: number;
  position: string;
}

interface IEmployeesDto {
  employees: IEmployes[];
}

interface ICommitType {
  commit(commit: string, payload: IEmployes[]): void;
}

interface IEmployesState {
  employes: IEmployes[];
  processE: boolean;
}

export default {
  actions: {
    async getAllEmployes(ctx: ICommitType): Promise<void> {
      const data = await fetch(`${BACKEND_URL}/employees`);
      const employees: IEmployeesDto = await data.json();
      ctx.commit('updateEmployes', employees.employees);
    },
  },
  mutations: {
    updateEmployes(
      state: IEmployesState,
      newEmployes: IEmployes[],
    ): void {
      state.employes = newEmployes;
      state.processE = false;
    },
  },
  state: (): IEmployesState => ({
    employes: [],
    processE: true,
  }),
  getters: {
    allEmployes(state: { employes: IEmployes[] }): IEmployes[] {
      return state.employes;
    },
    getProcessE(state: { processE: boolean }): boolean {
      return state.processE;
    },
  },
};
