
import { Head } from '@vueuse/head';
import VacanciesContent from '../components/VacanciesContent/VacanciesContent.vue';
import FeedBack from '../components/FeedBack/FeedBack.vue';

export default {
  components: {
    VacanciesContent,
    FeedBack,
    Head,
  },
};
