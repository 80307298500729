<template>
  <div :class='{container__soft_article: true, isProjects: isProjects}'
       v-html='getHTMLArticle(item.articlePath)'>
  </div>
</template>

<script>
import Glider from 'glider-js';
import mediumZoom from 'medium-zoom';

export default {
  props: ['item', 'isProjects'],
  methods: {
    getHTMLArticle(name) {
      setTimeout(() => {
        new Glider(document.querySelector('.glider-soft'), {
          slidesToScroll: 1,
          slidesToShow: 1.5,
          duration: 0.5,
          arrows: {
            prev: '.glider-prev',
            next: '.glider-next',
          },
          responsive: [
            {
              // If Screen Size More than 768px
              breakpoint: 0,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
            {
              // If Screen Size More than 1024px
              breakpoint: 768,
              settings: {
                slidesToShow: 1.5,
                slidesToScroll: 1,
              }
            }
          ]
        });
        mediumZoom('#zoom-default', {
          background: '#1c1d1e',
          scrollOffset: 0,
          margin: 24,
        });
      }, 100);
      return require(`../../../public/html/` + name);
    },
  },
};
</script>

<style lang='scss'>
@import '../../styles/colors.scss';

.glider-soft::-webkit-scrollbar {
  display: none;
}

.glider-soft {
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  .glider-track {
    height: inherit !important;
    padding-top: 0 !important;
    padding-left: 0 !important;
    width: max-content !important;
    gap: 25px;
  }

  @media screen and (max-width: 767px) {
    .glider-track {
      gap: 25px;
    }
  }
}
</style>
