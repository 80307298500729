
import { mapGetters } from 'vuex';
import moment from 'moment';
import BackItem from '@/components/BackItem/BackItem.vue';
import SoftItemHTML from '@/components/SoftItemHTML/SoftItemHTML.vue';

export default {
  components: {
    BackItem,
    SoftItemHTML
  },
  props: ['loading', 'item', 'isProjects'],
  methods: {
    toCapitalazeFirst(str: string) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    },
    getDomain(url: string, anotherUrl: string): string {
      let urlR = '';
      try {
        urlR = (new URL(url)).hostname.replace('www.', '');
      } catch (e) {
        urlR = anotherUrl;
      }
      return urlR;
    },
  },
  computed: {
    ...mapGetters(['getStaticLanguage']),
    transformDate() {
      return (value: string) => {
        if (value) {
          const date = new Date(value);
          return moment(String(date)).format('MMMM DD, YYYY').toUpperCase();
        }
        return '';
      };
    },
  },
};
