
import { mapGetters } from 'vuex';

export default {
  name: 'header-about',
  computed: mapGetters(['getLanguage', 'getStaticLanguage']),
  methods: {
    handleScroll(): void {
      document.querySelector('.find__people_cont')!.scrollIntoView({
        behavior: 'smooth',
      });
    },
  },
};
