<template>
  <div class="glider">
    <div
      class="element_employes"
      v-for="employes in allEmployes"
      :key="employes.id"
    >
      <element-employe :employes="employes" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Glider from 'glider-js';
import ElementEmploye from '../ElementEmploye/ElementEmploye.vue';

export default {
  components: {
    ElementEmploye,
  },
  computed: mapGetters(['getMobile']),
  props: ['allEmployes'],
  methods: {
    handleScroll() {
      let cofficientDel = 140;
      let leftPosition;
      if (this.getMobile) {
        cofficientDel = 121.75;
        leftPosition = Math.floor(
          document.querySelector('.glider').scrollLeft / cofficientDel
        );
      } else {
        leftPosition = Math.ceil(
          document.querySelector('.glider').scrollLeft / cofficientDel
        );
      }
      document.querySelectorAll('.element_employes').forEach(item => {
        item.classList.remove('act');
      });
      document
        .querySelectorAll('.element_employes')
        [leftPosition]?.classList.add('act');
    },
    removeActive(activeItem) {
      document.querySelectorAll('.element_employes').forEach(item => {
        item.classList.remove('act');
      });

      activeItem.classList.add('act');
    },
  },
  mounted() {
    new Glider(document.querySelector('.glider'));
    document
      .querySelector('.glider')
      .addEventListener('scroll', this.handleScroll);
    document.querySelectorAll('.element_employes').forEach(item => {
      item.addEventListener('click', () => this.removeActive(item));
    });
    document.querySelectorAll('.element_employes')[0].classList.add('act');
  },
};
</script>

<style lang="scss">
@media screen and (max-width: 1023px) {
  .element_employes {
    width: 140px !important;
    margin-right: 12px;
    height: 140px !important;
  }
  .element_employes:last-child {
    padding-right: 500px;
    img {
      min-width: 140px;
    }
    .content_front {
      left: -90px;
      transform: none;
    }
  }
  .element_employes.act {
    .content_front {
      opacity: 1;
      visibility: visible;
    }
    img {
      opacity: 1;
      transform: scale(1.2);
    }
    .code_employes {
      transform: none;
    }
  }
}
.glider-track {
  height: 300px;
  padding-top: 20px;
  padding-left: 94px;
  width: 1000px !important;
}
.glider::-webkit-scrollbar {
  display: none;
}
.glider {
  overflow: auto;
  -ms-overflow-style: none; 
  scrollbar-width: none; 
}
@media screen and (max-width: 767px) {
  .glider-track {
    padding-left: 16px;
  }
  .element_employes.act {
    .content_front {
      opacity: 1;
      visibility: visible;
    }
    img {
      opacity: 1;
      transform: scale(1.1);
    }
    .code_employes {
      transform: none;
    }
  }
  .element_employes {
    min-width: 120px !important;
    max-height: 120px !important;
    img {
      height: 120px;
    }
  }
  .element_employes:last-child {
    padding-right: calc(100vw - 120px);
    img {
      min-width: 120px;
    }
  }
}

@media screen and (max-width: 383px) {
  .element_employes:last-child {
    padding-right: 0;
  }
}
</style>
