
import { defineComponent } from '@vue/runtime-core';
import { mapGetters, mapMutations } from 'vuex';
import CloseModalLayout from '../CloseModalLayout/CloseModalLayout.vue';
import FormVacancies from '../FormVacancies/FormVacancies.vue';
import HrLinks from '../HrLinks/HrLinks.vue';

export default defineComponent({
  components: {
    FormVacancies,
    HrLinks,
    CloseModalLayout,
  },
  methods: {
    ...mapMutations(['setModal']),
    handleScrollVacan() {
      this.$refs.formVacan!.scrollIntoView({
        behavior: 'smooth',
      });
    },
  },
  computed: mapGetters(['getVacan', 'getModal', 'getStaticLanguage']),
});
