
import { defineComponent } from '@vue/runtime-core';
import { Head } from '@vueuse/head';
import FeedBack from '../components/FeedBack/FeedBack.vue';
import SoftItem from '../components/SoftItem/SoftItem.vue';
import { BACKEND_URL } from '@/constantURL';
import { IBlog } from '../modules/blogModule';

export default defineComponent({
  data(): {blogs: IBlog, loading: boolean } {
    return { blogs: {} as IBlog, loading: true };
  },
  methods: {},
  computed: {
  },
  components: {
    FeedBack,
    Head,
    SoftItem
  },
  async created(): Promise<void> {
    this.loading = true;
    const param = this.$route.params.id;
    if (param) {
      const data = await fetch(`${BACKEND_URL}/blogs/${param}`);
      const blogs: IBlog = await data.json();
      if (data.status === 200) {
        this.blogs = blogs;
        this.loading = false;
      } else {
        this.$router.push('/blog');
      }
    } else {
      this.$router.push('/blog');
    }
  },
});
