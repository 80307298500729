const languageRu = {
  links: [
    {
      path: '/about-us',
      word: 'О нас',
    },
    {
      path: '/vacancies',
      word: 'Вакансии',
    },
    {
      path: '/projects',
      word: 'Наши проекты',
    },
    {
      path: '/blog',
      word: 'Блог',
    },
  ],
  logoHead: 'Разрабатываем сервисы',
  logoHeadSpan: 'в области социальных сетей',
  textHead: ' Мы постоянно в поиске сильных специалистов с горящими глазами',
  buttonHeadText: 'Откликнуться на вакансию',
  dataItems: [
    {
      text: 'Суммарно наши разработки принесли',
      span: 'около двух миллионов долларов за все время',
      sum: '$2млн',
    },
    {
      text: 'В нашей ламповой команде работают',
      span: '17 заряженных бойцов',
      sum: '17',
    },
    {
      text: 'Мы запустили',
      span: 'более пяти продуктов только за 2020 год',
      sum: '>5',
    },
    {
      text: 'За прошлый год наши продукты установили',
      span: 'около пяти миллионов людей',
      sum: '5млн',
    },
  ],
  textGift: 'У нас есть',
  textGiftSpan: 'не только печеньки',
  gifts: [
    {
      path: './images/Office.json',
      main: 'Офис в центре города',
      text:
        'Удобное расположение офиса: находимся в двух минутах ходьбы от метро Молодежная',
    },
    {
      path: './images/Clocks.json',
      main: 'Гибкий график',
      text:
        'Удобное время работы: график найдётся как для жаворонков, так и для сов',
    },
    {
      path: './images/Gift.json',
      main: 'Подарки на дни рождения',
      text:
        'Не важно кто ты: разработчик, менеджер или дизайнер. Выбирай что тебе подарить',
    },
    {
      path: './images/Support.json',
      main: 'Поддержка опытных коллег',
      text:
        'Команда талантливых и проактивных профессионалов готова прийти на помощь',
    },
  ],
  textEmployes: 'Наша мощная команда',
  textEmployesSpan: 'открыта к пополнению',
  textFindPeople: 'Внимание! Розыск!',
  viewVacancies: 'Смотреть все вакансии',
  feedBack: 'HR LinkedIn',
  feedBackText: 'Оставьте ваши данные и мы с вами свяжемся в ближайшее время',
  formName: 'Ваше имя',
  formPosition: 'Желаемая позиция',
  formLink: 'Телефон или Linkedin',
  formGithub: 'Github',
  formButton: 'Отправить',
  formPrivacy: 'Согласен(-на) с',
  formPrivacyMain: 'Условиями обработки персональных данных',
  rights: 'Все права защищены',
  internsText: 'Стажировки',
  vacanciesPaid: 'Ищем в команду',
  successSend: 'Данные успешно отправлены',
  successSendContact:
    'В ближайшее время мы свяжемся с Вами по указанному контакту',
  successSendButton: 'Хорошо',
  privacyTitle: 'Политика по сбору и обработке персональных данных',
  privacyText1:
    'Добрый день, уважаемый пользователь (пользовательница) ресурса https://zetreex.com/',
  privacyText2:
    'Настоящий документ позволит понять, каким образом будет происходить сбор и обработка персональных данных.',
  privacyMain1: '1. Цели сбора персональных данных.',
  privacyLabel1: '1.1 Для связи с соискателем вакансии;',
  privacyLabel2:
    '1.2 Для формирования резервного банка данных соискателей ООО «Asura Engineering».',
  privacyMain2:
    '2. Персональные данные, которые может собирать ресурс https://zetreex.com/',
  privacyLi1:
    'Имя, желаемая позиция, телефон или ссылка на профиль социальной сети Linkedin, в случае заполнения полей на сайте пользователем;',
  privacyLi2: 'Файлы Cookie.',
  privacyMain3:
    '3. Согласие на какие действия с персональными данными даёт пользователь ресурса https://zetreex.com/',
  privacyLabel3:
    'Пользователь даёт согласие на сбор и обработку персональных данных ООО «Asura Engineering». Это означает согласие на получение от пользователя, хранение, систематизацию, изменение, использование (внутри ООО «Asura Engineering»), блокировку и удаление персональных данных ООО «Asura Engineering».',
  privacyLabel4:
    'ООО «Asura Engineering» обязуется использовать данную информацию только в целях, указанных в п. 1 настоящей Политики по обработке персональных данных и не передавать персональные данные пользователя другим организациям/физическим лицам.',
  privacyMain4:
    '4. Срок, на который даётся согласие на действия с персональными данными.',
  privacyLabel5:
    'Срок хранения персональных данных составляет 5 (пять) лет с момента их предоставления. По окончании этого срока, данные уничтожаются ООО «Asura Engineering».',
  privacyMain5: '5. Права пользователя ресурса https://zetreex.com/',
  privacyLabel6:
    'Здесь будет длинный пункт со всеми правами, которые пользователь имеет в отношении персональных данных. Они приведены в соответствии с проектом Закона Республики Польша «О персональных данных».',
  privacyLi3:
    'получать от ООО «Asura Engineering» информацию о своих правах, связанных со сбором, обработкой, распространением, предоставлением персональных данных;',
  privacyLi4:
    'давать согласие на сбор, обработку (за исключением обезличивания), распространение, предоставление своих персональных данных;',
  privacyLi5:
    'отзывать согласие на сбор и обработку (за исключением обезличивания) персональных данных – в этом случае, ООО «Asura Engineering» будет обязано прекратить сбор или удалить личные данные пользователя с момента такого отзыва;',
  privacyLi6:
    'знакомиться со своими персональными данными, требовать внесения в них изменений;',
  privacyLi7:
    'получать информацию о предоставлении своих персональных данных третьим лицам;',
  privacyLi8:
    'требовать прекращения сбора, обработки (за исключением обезличивания), распространения, предоставления своих персональных данных, в том числе распространенных персональных данных, и (или) их удаления при отсутствии оснований для таких действий с персональными данными;',
  privacyLi9:
    'требовать прекращения сбора, обработки (за исключением обезличивания), распространения, предоставления своих персональных данных (кроме распространенных) и (или) их удаления, если они не являются необходимыми для заявленной цели их сбора, обработки, распространения, предоставления либо истек срок сбора, обработки, распространения, предоставления персональных данных, указанный в согласии субъекта персональных данных или законодательном акте;',
  privacyLi10:
    'обжаловать действия (бездействие) и решения ООО «Asura Engineering», связанные со сбором, обработкой, распространением, предоставлением своих персональных данных;',
  privacyLi11:
    'осуществлять иные права, предусмотренные законодательством Республики Польша.',
  privacyMain6:
    '6. Механизм осуществления прав пользователя, указанных в п.5. настоящей Политики по сбору и обработке персональных данных.',
  privacyLabel7:
    'Пользователь имеет право на запрос отзыва согласия на сбор и обработку персональных данных, а также на их изменение и удаление и другие права, указанные в п. 5.',
  privacyLabel8: 'Для их осуществления этих прав пользователь может на выбор:',
  privacyLi12:
    'либо написать на электронный ящик info@zetreex.com сообщение в теме которого указать «Запрос о персональных данных», а в самом письме суть запроса и достаточные для идентификации пользователя данные (имя и телефон или email указанные при предоставлении персональных данных через форму на сайте), а также предпочитаемые способы связи;',
  privacyLi13:
    'либо написать письмо в ООО «Asura Engineering» на адрес Al. Armii Krajowej 45, 50-541, Wrocław, Poland с пометкой «Запрос о персональных данных». В этом письме также следует указать данные, достаточные для идентификации лица (имя и телефон или email указанные при предоставлении персональных данных через форму на сайте), а также предпочитаемые способы связи.',
  privacyLabel9:
    'Запрос будет рассмотрен ООО «Asura Engineering» и на него будет дан ответ в течение 15 (пятнадцати) календарных дней с даты его поступления в ООО «Asura Engineering».',
  privacyMain7: '7. Данные оператора персональных данных:',
  privacyNameCompany: 'ООО «Asura Engineering»',
  privacyLocaleCompany: 'Al. Armii Krajowej 45, 50-541, Wrocław, Poland',
  closeModal: 'Закрыть',
  applyVacancie: 'Откликнуться',
  responsibilities: 'Обязанности:',
  requirements: 'Требования:',
  advantages: 'Большим плюсом будет:',
  conditions: 'Условия:',
  inCount: 'в цифрах',
  feedback: 'HR LinkedIn',
  cookies: 'Дада, мы тоже собираем ваши куки, чтобы анализировать активность на сайте и улучшать его',
  cookiesOk: 'Ну ок',
  ourProjects: 'Наши проекты',
  blog: 'Блог',
  buttonMore: 'Подробнее',
  reverseEngineeringCategory: 'Обратная разработка',
  backPage: 'Вернуться назад',
  visitSite: 'Посетить сайт',
  visitArticle: 'Читать статью',
  visitTiktok: 'Посмотреть тикток',
  visitYoutube: 'Посмотреть ютуб',
  visitHabr: 'Посетить хабр',
  allProjectsCategory: 'All projects',
  allBlogCategory: 'All blogs',
};

const languageEn = {
  links: [
    {
      path: '/about-us',
      word: 'About Us',
    },
    {
      path: '/vacancies',
      word: 'Open Jobs',
    },
    {
      path: '/projects',
      word: 'Our projects',
    },
    {
      path: '/blog',
      word: 'Blog',
    },
  ],
  logoHead: 'We create services that enrich',
  logoHeadSpan: 'your social network experience',
  textHead:
    'We are always looking for diehard specialists with fire in their eyes',
  buttonHeadText: 'Apply now',
  dataItems: [
    {
      text: 'In total, our projects have earned',
      span: 'more than two million dollars',
      sum: '$2M',
    },
    {
      text: 'Our comfy team is a home to',
      span: '17 charged up fighters',
      sum: '17',
    },
    {
      text: 'We have launched',
      span: 'more than five projects in 2020 alone',
      sum: '>5',
    },
    {
      text: 'Five million new users',
      span: 'have installed our products last year',
      sum: '5M',
    },
  ],
  textGift: 'We have more',
  textGiftSpan: 'than just cookies',
  gifts: [
    {
      path: './images/Office.json',
      main: 'Office in downtown',
      text:
        'Convenient office location: just two minutes on foot from subway station',
    },
    {
      path: './images/Clocks.json',
      main: 'Flexible schedule',
      text:
        'Convenient working hours: there is a schedule for both larks and owls',
    },
    {
      path: './images/Gift.json',
      main: 'Birthday presents',
      text:
        'No matter who you are: developer, manager or designer. Ask and you shall recieve',
    },
    {
      path: './images/Support.json',
      main: 'Support from experienced colleagues',
      text:
        'A team of talented and proactive professionals is ready to help',
    },
  ],
  textEmployes: 'Our strong team',
  textEmployesSpan: 'is looking for new additions',
  textFindPeople: 'Attention! Wanted!',
  viewVacancies: 'View all open jobs',
  feedBack: 'Contact us',
  feedBackText:
    'Enter your contact details and we will get back to you as soon as possible',
  formName: 'Your name',
  formPosition: 'Desired position',
  formGithub: 'Github',
  formLink: 'Phone or Linkedin',
  formButton: 'Submit',
  formPrivacy: 'I agree to',
  formPrivacyMain: 'Privacy Policy',
  rights: 'All rights reserved',
  internsText: 'Internships',
  vacanciesPaid: 'Looking for a team',
  successSend: 'Information successfuly sent',
  successSendContact: 'We will get back to you shortly',
  successSendButton: 'Ok',
  privacyTitle: 'Personal data collection and processing policy',
  privacyText1:
    'Good afternoon, dear user (user) of the resource https://zetreex.com/',
  privacyText2:
    'This document will allow you to understand how the collection and processing of personal data will take place.',
  privacyMain1: '1. Purpose of collecting personal data.',
  privacyLabel1: '1.1 To contact the job seeker;',
  privacyLabel2:
    '1.2 To form a reserve database of applicants for Asura Engineering LLC.',
  privacyMain2:
    '2. Personal data that can be collected by the resource https://zetreex.com/',
  privacyLi1:
    'Name, desired position, phone number or link to the profile of the social network Linkedin, if the user fills in the fields on the website;',
  privacyLi2: 'Cookies.',
  privacyMain3:
    '3. Consent to what actions with personal data is given by the user of the resource https://zetreex.com/',
  privacyLabel3:
    'The user agrees to the collection and processing of personal data by Asura Engineering LLC. This means consent to receive from the user, storage, systematization, change, use (within Asura Engineering LLC), blocking and deletion of personal data of Asura Engineering LLC.',
  privacyLabel4: `LLC "Asura Engineering" undertakes to use this information only for the purposes specified in clause 1 of this Policy for the processing of personal data and not to transfer the user's personal data to other organizations / individuals.`,
  privacyMain4:
    '4. The period for which consent is given to actions with personal data.',
  privacyLabel5:
    'The storage period for personal data is 5 (five) years from the date of their provision. At the end of this period, the data is destroyed by Asura Engineering LLC.',
  privacyMain5: '5. Rights of the user of the resource https://zetreex.com/',
  privacyLabel6:
    'There will be a long paragraph with all the rights that the user has in relation to personal data. They are given in accordance with the draft Law of the Republic of Poland "On Personal Data".',
  privacyLi3:
    'to receive from LLC "Asura Engineering" information about their rights related to the collection, processing, distribution, provision of personal data;',
  privacyLi4:
    'consent to the collection, processing (with the exception of anonymization), distribution, provision of your personal data;',
  privacyLi5: `revoke consent to the collection and processing (with the exception of anonymization) of personal data - in this case, Asura Engineering LLC will be obliged to stop collecting or delete the user's personal data from the moment of such revocation;`,
  privacyLi6:
    'get acquainted with your personal data, request changes to them;',
  privacyLi7:
    'receive information about the provision of their personal data to third parties;',
  privacyLi8:
    'demand the termination of the collection, processing (with the exception of anonymization), distribution, provision of personal data, including disseminated personal data, and (or) their deletion in the absence of grounds for such actions with personal data;',
  privacyLi9:
    'demand the termination of the collection, processing (except for anonymization), distribution, provision of your personal data (except for the widespread) and (or) their deletion, if they are not necessary for the stated purpose of their collection, processing, distribution, provision, or the collection, processing period has expired , distribution, provision of personal data specified in the consent of the subject of personal data or legislative act;',
  privacyLi10:
    'to appeal against actions (inaction) and decisions of Asura Engineering LLC related to the collection, processing, distribution, provision of their personal data;',
  privacyLi11:
    'exercise other rights provided for by the legislation of the Republic of Poland.',
  privacyMain6: `6. The mechanism for exercising the user's rights specified in clause 5. of this Policy on the collection and processing of personal data.`,
  privacyLabel7:
    'The user has the right to request revocation of consent to the collection and processing of personal data, as well as to change and delete them and other rights specified in clause 5.',
  privacyLabel8: 'To exercise these rights, the user can choose to:',
  privacyLi12:
    'or write a message to info@zetreex.com in the subject line of which you should indicate "Request for personal data", and in the letter itself the essence of the request and data sufficient to identify the user (name and phone number or email specified when providing personal data through the form on the site) as well as preferred communication methods;',
  privacyLi13:
    'or write a letter to LLC "Asura Engineering" to the address Al. Armii Krajowej 45, 50-541, Wrocław, Poland marked “Request for personal data”. In this letter, you should also indicate data sufficient to identify a person (name and phone number or email specified when providing personal data through the form on the site), as well as preferred methods of communication.',
  privacyLabel9:
    'The request will be considered by Asura Engineering LLC and will be answered within 15 (fifteen) calendar days from the date of its receipt by Asura Engineering LLC.',
  privacyMain7: '7. Data of the operator of personal data:',
  privacyNameCompany: 'LLC "Asura Engineering"',
  privacyLocaleCompany: 'Al. Armii Krajowej 45, 50-541, Wrocław, Poland',
  closeModal: 'Close',
  applyVacancie: 'Apply now',
  responsibilities: 'Responsibilities:',
  requirements: 'Minimum Qualifications:',
  advantages: 'Preferred Qualifications:',
  conditions: 'Perks:',
  inCount: 'in numbers',
  feedback: 'HR LinkedIn',
  cookies: 'Yes, we also collect your cookies to analyze site activity and improve it',
  cookiesOk: 'Well, ok',
  ourProjects: 'Our projects',
  blog: 'Blog',
  allProjectsCategory: 'All projects',
  allBlogCategory: 'All blogs',
  buttonMore: 'In more detail',
  backPage: 'Come back',
  visitSite: 'Visit web-site',
  visitArticle: 'Read article',
  visitTiktok: 'View tiktok',
  visitYoutube: 'View YouTube',
  visitHabr: 'Visit habr'
};

export { languageRu, languageEn };
