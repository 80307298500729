
import { defineComponent } from '@vue/runtime-core';
import { mapActions } from 'vuex';
import { Head } from '@vueuse/head';
import HeaderAbout from '../components/HeaderAbout/HeaderAbout.vue';
import AdvantagesCompany from '../components/AdvantagesCompany/AdvantagesCompany.vue';
import Gifts from '../components/Gifts/Gifts.vue';
import AboutEmployes from '../components/AboutEmployes/AboutEmployes.vue';
import FindPeople from '@/components/FindPeople/FindPeople.vue';
import FeedBack from '@/components/FeedBack/FeedBack.vue';
import CookiesModal from '@/components/Cookies/CookiesModal.vue';

export default defineComponent({
  methods: {
    ...mapActions([
      'getAllEmployes',
    ]),
  },
  components: {
    CookiesModal,
    HeaderAbout,
    AdvantagesCompany,
    Gifts,
    AboutEmployes,
    FindPeople,
    FeedBack,
    Head,
  },
  async mounted(): Promise<void> {
    await this.getAllEmployes();
  },
});
