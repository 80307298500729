
import { defineComponent } from '@vue/runtime-core';

export default defineComponent({
  props: ['categories', 'setCategories'],
  methods: {
    getCorrectUppercaseCategory(category: string): string {
      if (!category) return '';
      return category[0].toUpperCase() + category.slice(1).toLowerCase();
    }
  }
});
