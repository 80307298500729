import { IVacancies } from './vacanciesModule';

const bodyLock = (modal: boolean) => {
  if (modal) {
    document.body.classList.add('body__lock');
  } else document.body.classList.remove('body__lock');
};

export default {
  actions: {},
  mutations: {
    setModal(state: { modal: boolean }, newModal: boolean): void {
      state.modal = newModal;
      bodyLock(newModal);
    },
    setVacan(
      state: { modal: boolean; activeVacan: IVacancies },
      newVacan: IVacancies
    ): void {
      state.activeVacan = newVacan;
    },
    setModalLetter(
      state: { modalLetter: boolean },
      newModalLetter: boolean
    ): void {
      state.modalLetter = newModalLetter;
      bodyLock(newModalLetter);
    },
    setModalPrivacy(
      state: { modalPrivacy: boolean; modal: boolean },
      newModalPrivacy: boolean
    ): void {
      state.modalPrivacy = newModalPrivacy;
      bodyLock(newModalPrivacy);
      if (state.modal) {
        document.body.classList.add('body__lock');
      }
    },
    setMenu(state: { menu: boolean }, newMenu: boolean): void {
      bodyLock(newMenu);
      state.menu = newMenu;
    },
    setTablet(state: { tablet: boolean }, newTablet: boolean): void {
      state.tablet = newTablet;
    },
    setMobile(state: { mobile: boolean }, newMobile: boolean): void {
      state.mobile = newMobile;
    },
  },
  state: (): {
    modal: boolean;
    activeVacan: Partial<IVacancies> | null;
    modalLetter: boolean;
    modalPrivacy: boolean;
    menu: boolean;
    tablet: boolean;
    mobile: boolean;
  } => ({
    modal: false,
    modalLetter: false,
    activeVacan: null,
    modalPrivacy: false,
    menu: false,
    tablet: false,
    mobile: false,
  }),
  getters: {
    getModal(state: { modal: boolean }): boolean {
      return state.modal;
    },
    getVacan(state: { activeVacan: IVacancies }): IVacancies {
      return state.activeVacan;
    },
    getModalLetter(state: { modalLetter: boolean }): boolean {
      return state.modalLetter;
    },
    getModalPrivacy(state: { modalPrivacy: boolean }): boolean {
      return state.modalPrivacy;
    },
    getMenu(state: { menu: boolean }): boolean {
      return state.menu;
    },
    getTablet(state: { tablet: boolean }): boolean {
      return state.tablet;
    },
    getMobile(state: { mobile: boolean }): boolean {
      return state.mobile;
    },
  },
};
