import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1509f835"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "gifts__container" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "gifts__text" }
const _hoisted_4 = { class: "cont__gifts" }
const _hoisted_5 = ["id"]
const _hoisted_6 = { class: "text_gift_flex" }
const _hoisted_7 = { class: "gift__main_text" }
const _hoisted_8 = { class: "gift__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lottie_animation = _resolveComponent("lottie-animation")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, [
        _createTextVNode(_toDisplayString(_ctx.getStaticLanguage.textGift) + " ", 1),
        _createElementVNode("span", null, _toDisplayString(_ctx.getStaticLanguage.textGiftSpan), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getStaticLanguage.gifts, (gift, ind) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "gift__element",
            key: ind
          }, [
            _createElementVNode("div", {
              class: "gift__lottie",
              id: ind === 2 ? 'gift__lottie_3' : 'gift__lottie'
            }, [
              _createVNode(_component_lottie_animation, {
                path: gift.path
              }, null, 8, ["path"])
            ], 8, _hoisted_5),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("h2", _hoisted_7, _toDisplayString(gift.main), 1),
              _createElementVNode("p", _hoisted_8, _toDisplayString(gift.text), 1)
            ])
          ]))
        }), 128))
      ])
    ])
  ]))
}