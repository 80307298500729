
import { defineComponent } from '@vue/runtime-core';
import { mapGetters, mapMutations } from 'vuex';
import CloseModalLayout from '../CloseModalLayout/CloseModalLayout.vue';

export default defineComponent({
  components: { CloseModalLayout },
  computed: mapGetters(['getModalPrivacy', 'getStaticLanguage']),
  methods: {
    ...mapMutations(['setModalPrivacy']),
  },
});
